$('.js-scroll-section').each(function() {   
    $(this).scroll(function() {
        const gridWidth = $(this).find('.js-scroll-grid').width();
        const windowWidth = $(this).parents('.js-scroll-container').width();
        let gridScroll = 0;
        const indicator = $(this).siblings().find('.js-scroll-indicator');
        gridScroll = $(this).scrollLeft();
        let newScroll = (gridScroll / (gridWidth - windowWidth));
        if (newScroll > 1) {
            newScroll = 1;
        }
        const pixels = 150 * newScroll;
        indicator.css({"left": pixels});
    })
});