// TODO: could prob make this more dynamic
const questionSwitch = (currentQuestion) => ({
    1: 'productType',
    2: 'typeOfEnvironment',
    3: 'lockStyle',
    4: 'lockUpValue'
})[currentQuestion];

const buildAnswers = (answers, currentQuestion) => {
    let display = '';
    answers.forEach(answer => {
        const name = answer.name.toLowerCase();
        const imageName = answer.imageName;
        const selected = answer.selected ? 'checked' : '';
        const displayText = answer.displaytext ? `(${answer.displaytext})` : '';
        const displayTextHtml = displayText ? `<span class="find-lock-category-description">${displayText}</span>` : '';
        const isDisabled = answer.count === 0;

        display += `<div class="find-lock-answer ${isDisabled ? 'disabled' : ''}">
            <label class="custom-control-label find-lock-label-container" for="${name}${currentQuestion}">
            <input class="custom-control-input find-lock-input" type="radio" data-slug="${answer.slug}" name="${questionSwitch(currentQuestion)}" id="${name}${currentQuestion}" value="${name}" aria-label="${name}" ${selected}>
                <span class="find-lock-label">
                    <span class="find-lock-category">${name}</span>
                    ${displayTextHtml}
                </span>
            </label>
            <div class="find-lock-image-container">
                <img src="/img/product-selector/${imageName}" class="find-lock-image" alt="${name}">
            </div>
        </div>`;
    });

    return display;
}

export const questionHtml = (question, currentQuestion, questionHeader) => {
    return `<div class="find-lock-question">
                <h2 id="questionTitle" class="mx-auto w-md-75 text-center mb-2 mb-md-4">${questionHeader}</h2>
                <div class="find-lock-answers">
                    ${buildAnswers(question, currentQuestion)}
                </div>
            </div>`
}

export const breadcrumbItemHtml = (selectedAnswer) => {
   return `<li class="list-inline-item find-lock-user-answer">${selectedAnswer}</li>`;
}