
$(function cookiePolicy() {
	setTimeout(function(){ 
		$('#cookie-notification').css("bottom", 0);
	}, 1500);

	$('#cookie-notification-close').on('click tap', function () {
		$('#cookie-notification').css("bottom", -400);
		var date = new Date();
		date.setTime(date.getTime() + (365*24*60*60*1000));
		var expires = 'expires=' + date.toGMTString();
		document.cookie = 'cookie_policy_accepted=true;' + expires + '; secure; samesite=strict; path=/;';
	});
	
	if(document.cookie.indexOf('cookie_policy_accepted') >= 0) {
		$('#cookie-notification').remove();
	}
});