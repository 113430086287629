const floatingButtonContainer = $('#findLockContainer');
const floatingButtonSafetyContainer = $('#safetyButtonContainer');
const floatingButton = $('#findLockButton');
const floatingSafetyButton = $('#safetyButton');
const isMobile = window.matchMedia('(max-width: 575px)').matches;
const breadcrumbs = document.querySelectorAll('.breadcrumb a');

breadcrumbs.forEach((crumb, index) => {
    if (!floatingButtonContainer) {
        return;
    }
    if (crumb.textContent.toLowerCase().includes('lockout')) {
        floatingButtonContainer.addClass('d-none');
    }
})

//for pages with Find a Lock button and the Master Lock Newsletter Opt in Form
if (floatingButton.length > 0) {
    floatingButtonContainer.css({'opacity': 1});
    monitorFloatingButton();
    $(window).scroll(monitorFloatingButton);

    function monitorFloatingButton() {
        const containerPositionDesktop = floatingButtonContainer.offset().top + 385;
        const containerPositionMobile = floatingButtonContainer.offset().top + 700;
        const buttonPosition = $(window).scrollTop() + $(window).height() - 40;

        if (isMobile) {
            if (buttonPosition < containerPositionMobile) {
                if (!floatingButton.hasClass('position-fixed')) {
                    floatingButton.addClass('position-fixed');
                }
            } else {
                floatingButton.removeClass('position-fixed');
            }

        } else {
            if (buttonPosition < containerPositionDesktop) {
                if (!floatingButton.hasClass('position-fixed')) {
                    floatingButton.addClass('position-fixed');
                }
            } else {
                floatingButton.removeClass('position-fixed');
            }
        }
    }
}

//for pages with Assess Your Program safety button and the Safety Opt in Form
if (floatingSafetyButton.length > 0) {
    floatingButtonSafetyContainer.css({'opacity': 1});
    monitorFloatingSafetyButton();
    $(window).scroll(monitorFloatingSafetyButton);

    function monitorFloatingSafetyButton() {
        const safetyButtonPositionDesktop = floatingButtonSafetyContainer.offset().top + 45;
        const safetyButtonPositionMobile = floatingButtonSafetyContainer.offset().top + 100;
        const safetyButtonPosition = $(window).scrollTop() + $(window).height() - 40;

        if (isMobile) {
            if (safetyButtonPosition < safetyButtonPositionMobile) {
                if (!floatingSafetyButton.hasClass('position-fixed')) {
                    floatingSafetyButton.addClass('position-fixed');
                }
            } else {
                floatingSafetyButton.removeClass('position-fixed');
            }

        } else {
            if (safetyButtonPosition < safetyButtonPositionDesktop) {
                if (!floatingSafetyButton.hasClass('position-fixed')) {
                    floatingSafetyButton.addClass('position-fixed');
                }
            } else {
                floatingSafetyButton.removeClass('position-fixed');
            }
        }
    }
}