import { questionHtml, breadcrumbItemHtml } from "./Transform";

export default class ProductSelector {
    constructor() {
        this.userAnswersEl = document.querySelector('#userAnswers');
        this.userAnswerListEl = document.querySelector('#userAnswersList');
        this.btnBackEl = document.querySelector('#btnBack');
        this.btnSearchEl = document.querySelector('#btnSearch');
        this.progressEl = document.querySelector('#progress');
        this.questionsEl = document.querySelector('#find-lock-questions');
        this.answers = document.getElementsByClassName('find-lock-question');
        this.actionsEl = document.querySelector('.find-lock-actions');
        this.modalBodyEl = document.getElementById('modalFindALockBody');
        this.isMobile = window.matchMedia('(max-width: 768px)');
        this.currentQuestion = 1;
        this.selections = {};
        this.questions = [];
        this.apiUrlBase = '/api/selector';
        this.searchUrlBase = '/products/selector';
        this.processingClick =false;
    }

    init() {
        // get questions list
        this.reset();
        try {
            this.getQuestionData(this.apiUrlBase + '/questions')
                .then(data => {
                    if (data && Object.keys(data).length > 0 ) {
                        this.questions = data.questions;
                    } else {
                        throw new Error('The request was completed successfully, but returned zero results');
                    }
                })
                .then(() => {
                    // get question data
                    try {
                        this.getQuestionData(this.apiUrlBase)
                            .then(data => {
                                if (data && Object.keys(data).length > 0) {
                                    // save initial state from api
                                    this.selections = data;
                                    // display first question
                                    this.addQuestion(data, this.currentQuestion, this.questions[this.currentQuestion-1]);
                                } else {
                                    throw new Error('The request was completed successfully, but returned zero results');
                                }
                            })
                            .then(() => {
                                this.showNextQuestion();
                                this.progressEl.classList.add('active');
                            });
                    } catch(error) {
                        throw new Error;
                    }
                });
        } catch(error) {
            throw error;
        }

        // bind events
        this.modalBodyEl.addEventListener('click', this.handleOptionClick.bind(this));
        this.btnBackEl.addEventListener('click', this.handleBackButtonClick.bind(this));
        this.btnSearchEl.addEventListener('click', this.handleSearchButtonClick.bind(this));
    }

    pushDataLayer(event, eventCategory, eventAction, eventLabel) {
        if (typeof dataLayer !== 'undefined') {
            var dataLayerObject = {
                'event': event,
                'eventCategory': eventCategory,
                'eventAction': eventAction,
                'eventLabel': eventLabel
            };

            dataLayer.push(dataLayerObject);
        }
    }

    handleOptionClick(event) {
        //console.log("processing click: "+this.processingClick);
        if (this.processingClick) {
            //console.log("reject you!");
            return;
        }
        //console.log("clicking, and processingClick = "+this.processingClick);
        if(!event.detail || event.detail == 1) { // prevent double clicking
            event.stopImmediatePropagation();
            const isTargetElement = event.target.tagName == 'INPUT';

            if (isTargetElement) {
                this.handleSelected(event);
            }
        }
    }

    handleBackButtonClick(event) {
        event.preventDefault();
        this.handleBack();
    }

    handleSearchButtonClick(event) {
        event.preventDefault();
        this.goToSearchResults();
    }

    addBreadcrumb(answer) {
        const listItem = breadcrumbItemHtml(answer);
        this.userAnswerListEl.insertAdjacentHTML('beforeend', listItem);
        const currentElSelector = `.find-lock-user-answer:nth-of-type(${this.currentQuestion - 1})`;
        const forceFlow = this.userAnswerListEl.getBoundingClientRect(); // force reflow so css transitions run
        this.userAnswerListEl.querySelector(currentElSelector).classList.add('active');
    }

    hasClass(elem, className) {
        return elem.classList.contains(className);
    }

    updateProgress(direction) {
        if (direction === 'next') {
            const progressEls = [...this.progressEl.querySelectorAll('.progress-item')];
            progressEls.forEach((item, index) => {
                if (index === (this.currentQuestion -1)) {
                    item.classList.add('complete');
                }
            })
        } else if (direction === 'prev') {
            const lastEl = [...this.progressEl.querySelectorAll('.complete')].pop();

            if (lastEl) {
                lastEl.classList.remove('complete');
            }
        }
    }

    buildUrlFromAnswers(type) {
        const answers = document.querySelectorAll('.find-lock-answer input');
        let url = '';
        if (type === 'api') {
            url = this.apiUrlBase;
        } else if (type === 'search') {
            url = this.searchUrlBase;
        } else if (type === 'local') {
            url = '';
        };

        [...answers].forEach(answer => {
            if (answer.checked) {
                url += '/' + answer.getAttribute('data-slug');
            };
        });

        const encodedUrl = encodeURI(url);

        return encodedUrl;
    }

    handleBack() {
        this.currentQuestion--;
        if (this.currentQuestion === 1) {
            this.userAnswersEl.classList.remove('active');
            this.userAnswerListEl.innerHTML = '';
            this.btnBackEl.classList.remove('active');
        }
        if (this.currentQuestion < 4) {
           this.btnSearchEl.setAttribute('disabled', 'disabled');
           this.btnSearchEl.classList.remove('active');
        }
        const answers = this.userAnswerListEl.querySelectorAll('li');
        if (answers.length > 1) {
            const index = answers.length - 1;
            answers[index].remove();
        }
        this.updateProgress('prev');
        this.pushDataLayer("Product Selector", "Product Selector", this.questions[this.currentQuestion], "Back");
        this.showPrevQuestion();
        this.processingClick=false;
    }

    handleSelected(event) {
        //console.log("processing click...");
        this.processingClick=true;
        const findLockAnswers = document.getElementsByClassName('find-lock-answer');
        for(let idx=0; idx<findLockAnswers.length;idx++) {

            findLockAnswers[idx].classList.remove('selected');

        }

        const targetWrapper = event.target.closest('.find-lock-answer');
        targetWrapper.classList.add('selected');

        if (this.currentQuestion === 4) {
            this.btnSearchEl.removeAttribute('disabled');
            this.btnSearchEl.classList.add('active');
            this.processingClick=false;

            return;
        }

        this.currentQuestion++;
        try {
            const url = this.buildUrlFromAnswers('api');
            this.getQuestionData(url)
                .then(data => {
                    if (data && Object.keys(data).length > 0 ) {
                        // save initial state from api
                        this.selections = Object.assign(this.selections, data);
                        // display first question
                        this.addQuestion(data, this.currentQuestion, this.questions[this.currentQuestion-1]);
                        this.addBreadcrumb(event.target.value);
                    } else {
                        throw new Error('The request was completed successfully, but returned zero results');
                    }
                })
                .then(() => {
                    if (this.currentQuestion > 1) {
                        this.userAnswersEl.classList.add('active');
                        this.btnBackEl.classList.add('active');
                    } else {
                        this.userAnswersEl.classList.remove('active');
                        this.btnBackEl.classList.remove('active');
                    }

                    this.updateProgress('next');
                    this.pushDataLayer("Product Selector", "Product Selector", this.questions[this.currentQuestion - 2], targetWrapper.innerText);
                })
                .then(() => {
                    setTimeout(() => {
                        this.showNextQuestion();
                        //console.log("done processing click")
                        this.processingClick=false;

                    }, 450);
                });
        } catch(error) {
            throw new Error;
        }
    }

    goToSearchResults() {
        const url = this.buildUrlFromAnswers('search');

        try {
            const question = this.questions[this.currentQuestion - 2];
            const answer = $(".selected")[this.currentQuestion - 1].innerText;
            this.pushDataLayer("Product Selector", "Product Selector", question, answer);
        } catch(err) {
            console.log("Error tracking selection");
        }

        this.pushDataLayer("Product Selector", "Product Selector", "See Locks", url);
        window.location.href = url;
    }

    async getQuestionData(url = '') {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.json();
    }

    questionSwitch(currentQuestion, questionData) {
        const type = (current, data) => ({
            1: data.productType,
            2: data.typeOfEnvironment,
            3: data.lockStyle,
            4: data.lockUpValue
        })[current]

        return type(currentQuestion, questionData);
    }

    addQuestion(data, currentQuestion, questionHeader) {
        const nextQuestionHtml = questionHtml(this.questionSwitch(currentQuestion, data), currentQuestion, questionHeader);
        this.questionsEl.insertAdjacentHTML('beforeend', nextQuestionHtml);
    }

    showNextQuestion() {
        //console.log("going to set processClick = false");
        this.processingClick=false;

        const currentElSelector = `.find-lock-question:nth-of-type(${this.currentQuestion})`;
        const currentEl = this.questionsEl.querySelector(currentElSelector);
        // forces reflow so CSS transitions added will run
        const forceFlow = currentEl.getBoundingClientRect();

        if (this.currentQuestion === 4) {
            this.btnSearchEl.classList.add('active');
        }

        this.resetQuestions();

        this.questionsEl.querySelector(currentElSelector).classList.add('active');
    }

    showPrevQuestion() {
        const isFirstQuestion = this.questionsEl.childNodes.length === 1;
        const questions = this.questionsEl.querySelectorAll('.find-lock-question');
        const currentElSelector = questions[this.currentQuestion - 1];
        const currentElAnswers = currentElSelector.querySelectorAll('.find-lock-answer');

        if (!isFirstQuestion) {
            const lastQuestionEl = questions[questions.length - 1];
            lastQuestionEl.remove();
        }

        [...currentElAnswers].forEach(element => {
            element.classList.remove("selected");
        });

        currentElSelector.classList.add('active');
    }

    resetQuestions() {
        const questions = this.questionsEl.querySelectorAll('.find-lock-question');
        [...questions].forEach(question => {
            question.classList.remove('active');
        });
    }

    reset() {
        this.currentQuestion = 1;
        this.selections = {};
        // this.questions = [];
        this.questionsEl.innerHTML = '';
        this.userAnswerListEl.innerHTML = '';
        this.userAnswersEl.classList.remove('active');
        this.btnBackEl.classList.remove('active');
        this.actionsEl.classList.remove('active');
        this.btnSearchEl.classList.remove('active');
        this.btnSearchEl.setAttribute('disabled', 'disabled');
        this.modalBodyEl.removeEventListener('click', this.handleOptionClick);
        this.btnBackEl.removeEventListener('click', this.handleBackButtonClick);
        this.btnSearchEl.removeEventListener('click', this.handleSearchButtonClick);

        // this.resetQuestions();

        const progressEls = [...this.progressEl.querySelectorAll('.progress-item')];
        progressEls.forEach((item, index) => {
            if (index > 0) {
                item.classList.remove('complete');
            }
        });
    }
};