import { checkDesktop } from "./desktopCheck.js";

const navCategories = $('.js-nav-category');
const siteHeader = $('.js-site-header');

function openDropdown(parent) {
    parent.find('[data-toggle=dropdown]').attr('aria-expanded','true');
    parent.find('.dropdown-menu').addClass('show');
    siteHeader.addClass('dropdown-is-open');
}

function closeDropdown(parent) {
    parent.find('[data-toggle=dropdown]').attr('aria-expanded','false');
    parent.find('.dropdown-menu').removeClass('show');
    siteHeader.removeClass('dropdown-is-open');
}

function dropdownHover() {
    navCategories.hover(
        //hover in
        function() {
            openDropdown($(this));
        },
        //hover out
        function() {
            closeDropdown($(this));
        }
    );
}

function freezeDocument() {
    $('body').addClass('overflow-hidden');
}

function unfreezeDocument() {
    $('body').removeClass('overflow-hidden');
}

let isDesktop;
$(window).resize(function() {
    const newState = checkDesktop();
    if (isDesktop !== newState) {
        isDesktop = newState;
        if (isDesktop === true) {
            dropdownHover();
            $('.js-utility-nav').prependTo('.js-site-header');
            $('.js-main-nav').collapse('show');
        } else {
            navCategories.unbind("mouseenter mouseleave");
            $('.js-utility-nav').appendTo('.js-main-nav');
            $('.js-main-nav').collapse('hide');
        }
    }
}).resize();

let ticking = false;
let lastScrollPosition = $(window).scrollTop();
window.addEventListener('scroll', function(e) {
  if (!ticking) {
    window.requestAnimationFrame(function() {
        const scrollPosition = $(window).scrollTop();
        if (scrollPosition > lastScrollPosition || scrollPosition < 15) {
            $('.js-main-nav-container, .js-utility-nav').removeClass('position-fixed');
        } else if (scrollPosition < (lastScrollPosition - 15) && scrollPosition > 15) {
            $('.js-main-nav-container, .js-utility-nav').addClass('position-fixed');
        }
        lastScrollPosition = scrollPosition;
      ticking = false;
    });

        //when we're scrolling, close dropdowns
        if (isDesktop === true) {
            if ($('[data-toggle=dropdown][aria-expanded=true]').length > 0) {
                closeDropdown($('[data-toggle=dropdown][aria-expanded=true]').parents('.js-nav-category'));
            }
        }

        ticking = true;
    }
});

function checkActive() {
    const currentUrl = window.location.href;
    //portion of the URL after the ?
    const currentUrlFragment = currentUrl.split('?')[1];
    const topLevelNavItems = document.querySelectorAll('.js-nav-category > a');
    topLevelNavItems.forEach(function(item) {
        const thisUrl = item.href;
        const thisUrlFragment = thisUrl.split('?')[1];

        if (currentUrlFragment === thisUrlFragment) {
            item.classList.add("is-active");
            return;
        }
    });
};

$(function() {
    checkActive();
    $('.js-main-nav').on('hide.bs.collapse', function () {
        unfreezeDocument();
      });
    $('.js-main-nav').on('show.bs.collapse', function () {
        if (isDesktop === true) {
            unfreezeDocument();
        } else {
            freezeDocument();
        }
    })
});