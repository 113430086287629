const toTop = $('#toTop');

if (toTop.length > 0) {
    $(window).scroll(function() {
        if ($(this).scrollTop() > 800) {
            $(toTop).fadeIn();
        } else {
            $(toTop).fadeOut();
        }
    });

    $(toTop).on('click tap', function() {
        $("html, body").animate({scrollTop: 0}, 1000);
    });
}
