$(function() {
	$('[data-event]').on('click', function() {
		const thisElement = $(this);
		const event = thisElement.data('event');
		const eventCategory = thisElement.data('event-category');
		const eventAction = thisElement.data('event-action');
		let eventLabel;
		/* For promos, we'll get their CTA text here instead of always updating the markup */
		if($(this).attr('data-event') === "Promo Tile") {
			eventLabel = $(this).find('.btn').eq(0).text();
		} else {
			eventLabel = thisElement.data('event-label');
		}
		if (typeof dataLayer !== 'undefined') {
			var dataLayerObject = {
				'event': event,
				'eventCategory': eventCategory,
				'eventAction': eventAction,
				'eventLabel': eventLabel
			};

			dataLayer.push(dataLayerObject);
		}
	});
});