import {initVideos} from './videos.js';
const modelNumber = document.getElementById('modelNumber');

function handleSupportAndVideoData(json) {

    if (json == null) {
        document.getElementById("service-and-support").innerHTML="An error has occurred.";
        return;
    }
    let hasSupportInfo = false;

    // Add the product manuals
    if (json.manuals != null && json.manuals.length > 0) {
        let result = "";

        for (let i = 0; i < json.manuals.length; i++) {
            result += `<li class="mt-3"><a class="featured-text" href="${json.manuals[i].url}" target="_blank" data-event-support="Product Manual" data-event-category="Product Details Service & Support" data-event-action="Product Manual" data-event-label="${json.manuals[i].headLine}">${json.manuals[i].headLine}</a></li>`;
        }
        hasSupportInfo = true;
        document.getElementById("product-manual-list").innerHTML = result;
    } else {
        document.getElementById("product-manual").style.display = "none";
    }

    let hasRelatedInfo=false;
    // Add Related Information
    if (json.relatedInfoOnly != null && json.relatedInfoOnly.length > 0) {
        let result = "";

        for (let i = 0; i < json.relatedInfoOnly.length; i++) {
            let isPDF = json.relatedInfoOnly[i].url.endsWith(".pdf");
            let target="";
            if (isPDF) target="target='_blank'"
            result += `<li class="mt-3"><a class="featured-text" `+target+` href="${json.relatedInfoOnly[i].url}" data-event-support="Product Manual" data-event-category="Product Details Service & Support" data-event-action="Related Info" data-event-label="${json.relatedInfoOnly[i].headLine}">${json.relatedInfoOnly[i].headLine}</a></li>`;
        }
        hasRelatedInfo = true;
        document.getElementById("related-information-list").innerHTML = result;
    }

    // Add Compliance Information
    if (json.compliancePdfs != null && json.compliancePdfs.length > 0) {
        let result = "";

        for (let i = 0; i < json.compliancePdfs.length; i++) {
            let isPDF = json.compliancePdfs[i].url.endsWith(".pdf");
            let target="";
            if (isPDF) target="target='_blank'"

            result += `<li class="mt-3"><a class="featured-text" `+target+` href="${json.compliancePdfs[i].url}" data-event-support="Product Manual" data-event-category="Product Details Service & Support" data-event-action="Compliance Info" data-event-label="${json.compliancePdfs[i].headLine}">${json.compliancePdfs[i].headLine}</a></li>`;
        }
        hasRelatedInfo = true;
        document.getElementById("compliance-information-list").innerHTML = result;
    } else {
        document.getElementById("compliance-information").style.display = "none";
    }

    if (hasRelatedInfo) {
        hasSupportInfo = true;
    }
    else {
        document.getElementById("related-information").style.display = "none";
    }

    // Add Online Support FAQs
    if (json.faqs != null && json.faqs.length > 0) {
        let result = "";

        for (let i = 0; i < json.faqs.length; i++) {
            const thisAnswer = "answer-" + i;
            result += '<li class="mt-3">';
            try {
                result += `<a class="featured-text" href="${json.faqs[i].link}" data-event-support="Product Manual" data-event-category="Product Details Service & Support" data-event-action="Online Support" data-event-label="${json.faqs[i].question}">${json.faqs[i].question}</a>`;
                result += `<div id="${thisAnswer}" class="hidden"><h4 class="h5">${json.faqs[i].question}</h4> ${json.faqs[i].answer}</div>`;
            } catch (err) {
                console.error("Error adding FAQ");
            } finally {
                result += "</li>";
            }
        }
        hasSupportInfo = true;
        document.getElementById("online-support-list").innerHTML = result;
    } else {
        document.getElementById("online-support").style.display = "none";
    }

    // Add video section
    if (json.videos != null && json.videos.length > 0) {
        let result = "";

        for (let i = 0; i < json.videos.length; i++) {
            result += '<div class="col-12 col-lg-4">';
            try {
                result += `<a class="d-block lightbox-video" href="https://www.youtube.com/embed/${json.videos[i].youtubeId}" data-featherlight-iframe-width="540" data-featherlight-iframe-height="405" data-download-link="${json.videos[i].bcDownloadUrl}" data-event-support="Product Details Service & Support" data-event-category="Product Details Service & Support" data-event-action="Video" data-featherlight-iframe-allowfullscreen="true" data-event-label="${json.videos[i].title}">`;
                result += `<div class="position-relative overlay-effect"><img src="https://img.youtube.com/vi/${json.videos[i].youtubeId}/0.jpg" alt="'Video Thumbnail for ${json.videos[i].title}" class="w-100">`;
                result += `<img src="/img/play.svg" class="icon-play centered" alt="Open Video" /></div>`;
                result += `<span class="d-block text-dark font-weight-bold text-center mt-2 mb-3 text-capitalize px-3">${json.videos[i].title}</span></a>`;
            } catch (err) {
                console.error("Error adding video");
            } finally {
                result += '</div>';
            }
        }
        hasSupportInfo = true;
        document.getElementById("video-list").innerHTML = result;
        initVideos();
    } else {
        document.getElementById("video").style.display = "none";
    }

    $('[data-event-support]').on('click', function() {
        const thisElement = $(this);
        const event = thisElement.data('event-support');
        const eventCategory = thisElement.data('event-category');
        const eventAction = thisElement.data('event-action');
        const eventLabel = thisElement.data('event-label');
        if (typeof dataLayer !== 'undefined') {
            var dataLayerObject = {
                'event': event,
                'eventCategory': eventCategory,
                'eventAction': eventAction,
                'eventLabel': eventLabel
            };

            dataLayer.push(dataLayerObject);
        }
    });

    if (!hasSupportInfo) {
        document.getElementById("service-and-support").style.display = "none";
    }
}

function loadServiceAndSupport(modelNumber) {
    let url = "/product/" + modelNumber + "/support";
    loadDataFromURL(url, handleSupportAndVideoData, true);
}

document.addEventListener("DOMContentLoaded", function(event) {
    if (modelNumber) {
        loadServiceAndSupport(modelNumber.value);
    }
});
