import SplitText from "./SplitText.js";
import CSSRulePlugin from "./CSSRulePlugin.js";

if ($('[data-animation=brand]').length > 0) {

    var controller = new ScrollMagic.Controller();

    var rule = CSSRulePlugin.getRule("[data-animation] .header-accent:before");

    var brandVision = new SplitText("[data-animation=brand]", {type:"words"}),
        brandVisionTimeline = gsap.timeline(),
        brandNumChars = brandVision.words.length;
        brandVisionTimeline.to(rule, {duration: 1, cssRule: {opacity: "1"}}, '+=.5');

        if ($("[data-animation=brand] .btn").length > 0) {
            var button = $("[data-animation=brand] .btn");
            brandVisionTimeline.from(button, 1, {opacity:0}, '.5');
        }

        for(var i = 0; i < brandNumChars; i++){
            //random value used as position parameter
            brandVisionTimeline.from(brandVision.words[i], 1, {opacity:0}, Math.random() / 2);
        }

    new ScrollMagic.Scene({triggerElement: "[data-animation=brand]", duration: 0, triggerHook: .6})
        .setTween(brandVisionTimeline)
        .addTo(controller); // add indicators (requires plugin)

}