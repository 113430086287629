function addDataLayerListener() {
    $('[data-event-video-download]').on('click', function() {
        const thisElement = $(this);
        const event = thisElement.data('event-video-download');
        const eventCategory = thisElement.data('event-category');
        const eventAction = thisElement.data('event-action');
        const eventLabel = thisElement.data('event-label');
        if (typeof dataLayer !== 'undefined') {
            var dataLayerObject = {
                'event': event,
                'eventCategory': eventCategory,
                'eventAction': eventAction,
                'eventLabel': eventLabel
            };

            dataLayer.push(dataLayerObject);
        }
    });
}


export function initVideos() {
    const isMobile = window.matchMedia('(max-width: 600px)').matches;

    const youtube_parser = function(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[2].length == 11) {
            return match[2];
        } else {
            return url;
        }
    }

    if (isMobile) {
        // YouTube videos
        $('.lightbox-video').on('click tap', function(e) {
            e.preventDefault();
            var videoName = $(this).text();
            window.open($(this).attr('href'));
            ga('send', 'event', 'Video Play', 'click', videoName);
            console.log('clicked play button');
        });

        // Brightcove videos
        $('.lightbox-video-brightcove').on('click tap', function(e) {
            e.preventDefault();
            var videoName = $(this).text();
            window.open($(this).attr('href'));
            location.replace(location.href);
            ga('send', 'event', 'Video Play', 'click', videoName);
        });

        $('.easyzoom').removeClass('easyzoom--adjacent').addClass('easyzoom--overlay');

    } else {
        // YouTube videos
        $('.lightbox-video').attr('href', function() {
            return 'https://www.youtube.com/embed/' +
                youtube_parser($(this).attr('href'));
        });

        // brightcove (from legacy code)
        $('.lightbox-video-brightcove').on('click tap', function(e) {
            var $this = $(this);
            var videoName = $this.text().trim();
            //ga('send', 'event', 'Video Play', 'click', videoName);
            if (window.matchMedia('(min-width: 601px)').matches) {
                e.preventDefault();
                $.featherlight({
                   // root: '.legacy #container',
                    href: '.featherlight',
                    type: 'html',
                    padding: 0,
                    beforeOpen: function() {
                        var video = $this.attr('href');
                        var $featherlightContainer = $('.featherlight-content');
                        $featherlightContainer.append(
                            '<video src="' + video + '" controls style="width:600px"></video>'
                        );
                        if ($this.attr('data-download-link')) {
                            var downloadLink = $this.data('download-link');
                            $featherlightContainer.append(
                                '<div class="video-download-modal-container">' +
                                '<a id="downloadLink" href="' + downloadLink + '" target="_blank" rel="noopener">' +
                                '<span class="fa fa-download" aria-hidden="true"></span> Download Video' +
                                '</a> <b>Right-click</b> on the &ldquo;Download Video&rdquo; link to save the video (MP4 format) to your computer.' +
                                '</div>');
                            $('#downloadLink').on('click tap', function() {
                                ga('send', 'event', 'Video Play', 'download', videoName);
                            });
                        }
                    },
                    afterContent: function() {
                        $('.featherlight-content').find('video')[0].play();
                    },
                    afterClose: function() {
                        $('.featherlight-content').remove();
                    }
                });
            }
        });

        // YouTube videos
        $('.lightbox-video').featherlight({
            type: 'iframe',
            beforeContent: function() {
                $('.featherlight-content').addClass('bg-dark').append('<div class="js-lightbox-loading loading"><span class="sr-only">Loading</span></div>');
            },
            afterOpen: function(event) {
                var videoTitle = event.currentTarget.outerText;

                const download = this.$currentTarget.attr('data-download-link');
                $('.js-lightbox-loading').remove();
                $('.featherlight-content').removeClass('bg-dark');
                if (download) {
                    $('.featherlight-content').append(
                        '<div class="video-download-modal-container" style="padding:10px;">' +
                            '<a id="downloadLink" target="_blank" rel="noopener" href="' + download + '" data-event-video-download="Video Download" data-event-category="Service & Support Videos" data-event-action="Download Video" data-event-label="' + videoTitle + '">' +
                                '<svg class="icon-download" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="download" class="svg-inline--fa fa-download fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path></svg> Download Video' + 
                            '</a>&#32;&#32;<b>Right&#45;click</b> on the &#34;Download Video&#34; link to save the video &#40;MP4 format&#41; to your computer.' +
                        '</div>'
                    );

                    addDataLayerListener();

                    $('#downloadLink').on('click tap', function() {
                        ga('send', 'event', 'Video Play', 'download');
                    });
                }
            }
        });
    }
}

$(function() {
    if ($('.lightbox-video').length > 0 || $('.lightbox-video-brightcove').length > 0) {
        initVideos();
    }
});