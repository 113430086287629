import Modal from '../components/Modal';
import ProductSelector from "./ProductSelector";

(() => {
    const findALockBtns = document.getElementsByClassName('js-launch-fal');
    const modal = document.getElementById('modalFindALock');

    if (modal && findALockBtns.length) {
        const body = document.body;
        const mqSmTouchDevices = window.matchMedia('(max-width: 1024px) and (hover: none) and (pointer: coarse)');

        Array.from(findALockBtns)
            .forEach(element => {
                element.addEventListener('click', (event) => {
                    event.preventDefault();
                    const floatingButton = document.querySelector('.js-floating-button-container');
                    const Selector = new ProductSelector();
                    new Modal({
                        container: 'modalFindALock',
                        beforeShow() {
                            Selector.init();
                            if (floatingButton !== null) {
                                floatingButton.classList.add('d-none');
                            }
                            if (!mqSmTouchDevices.matches) {
                                body.classList.add('overflow-hidden');
                            }
                        },
                        beforeClose() {
                            if (floatingButton !== null) {
                                floatingButton.classList.remove('d-none');
                            }
                            Selector.reset();
                        },
                        afterClose() {
                            if (!mqSmTouchDevices.matches) {
                                body.classList.remove('overflow-hidden');
                            }
                        }
                    });
                });
            });
    }
})()