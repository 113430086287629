import { checkDesktop } from "./desktopCheck.js";

    const categoriesWrapper = $('.js-categories');
    const categories = $('.js-category');
    const mobileButtons = $('.js-accordion-category-button');
    const desktopButtons = $('.js-category-button');
    const categoryInnerLinks = $('.js-category a');

    if (categories.length > 0) {

        let categoryAnimations = new ScrollMagic.Controller();

        mobileButtons.click(function() {
            const thisCategory = $(this).attr('data-category');
            toggleAccordionCategory(thisCategory,$(this));
            const thisCategoryTop = $(this).offset().top - 100;
            $(window).scrollTop(thisCategoryTop);
        });

        categoryInnerLinks.focus(function() {
            //console.log("focused");
            const parent = $(this).parents('.js-category');
            const parentCategory = parent.attr('id');
            if (parent.attr('data-category-open') !== 'true') {
                desktopButtons.filter('[data-category=' + parentCategory + ']').click();
            } 
        });

        $('.js-category-button').click(function() {
            const thisCategory = $(this).attr('data-category');
            toggleTabsCategory(thisCategory, $(this));
            if (categoriesWrapper.hasClass('categories-animation')) {
                categoriesWrapper.removeClass('categories-animation');
            }
        });

        $('.js-category-back').click(function() {
            const currentCategory = desktopButtons.index(desktopButtons.filter('[aria-expanded=true]'));
            const newCategory = currentCategory - 1;
            if (currentCategory > 0) {
                const newButton = desktopButtons.eq(newCategory);
                newButton.click();
            }
        });

        $('.js-category-forward').click(function() {
            const currentCategory = desktopButtons.index(desktopButtons.filter('[aria-expanded=true]'));
            const newCategory = currentCategory + 1;
            if (currentCategory <= desktopButtons.length) {
                const newButton = desktopButtons.eq(newCategory);
                newButton.click();
            }
        });

        function toggleAccordionCategory(category,button) {
            const thisCategory = $('#' + category);
            const isOpen = thisCategory.attr('data-category-open');
            if (isOpen === 'true') {
                thisCategory.attr('data-category-open','false');
                button.attr('aria-expanded','false');
            } else {
                thisCategory.attr('data-category-open','true');
                button.attr('aria-expanded','true');
            }
        }

        function toggleTabsCategory(category,button) {
            const thisCategory = $('#' + category);
            const categoryWrapper = $('.js-categories');
            const isOpen = thisCategory.attr('data-category-open');
            if (isOpen === 'true') {
                return;
            } else {
                categories.attr('data-category-open','false');
                thisCategory.attr('data-category-open','true');
                desktopButtons.attr('aria-expanded','false').removeAttr('disabled');
                button.attr('aria-expanded','true').attr('disabled','true');
            }
            if (categoryWrapper.hasClass('initial') && categoryWrapper.hasClass('in-view')) {
                categoryWrapper.removeClass('initial');
            }
        }

        function prepMobile() {
            //open the first section
            const firstCategory = mobileButtons.eq(0);
            toggleAccordionCategory(firstCategory.attr('data-category'),firstCategory);
        }

        function prepDesktop() {
            desktopButtons.eq(0).click();
            categoriesWrapper.addClass('categories-animation');
            
            new ScrollMagic.Scene({
                triggerElement: '[data-animate-container=categories]',
                offset: 200,
                reverse: false
            })
            .setClassToggle('.js-categories', 'in-view')
            //.addIndicators()
            .addTo(categoryAnimations); 
        }

        let isDesktop;
        $(window).resize(function() {
            const newState = checkDesktop();
            if (isDesktop !== newState) {
                isDesktop = newState;
                if (isDesktop === true) {
                    prepDesktop();
                } else {
                    prepMobile();
                }
            }
        }).resize();

    }