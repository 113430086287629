import dialogPolyfill from 'dialog-polyfill';

export default options => {
    const {
        container,
        beforeShow,
        afterShow,
        beforeClose,
        afterClose
    } = options;

    if (container) {
        const modal = document.getElementById(container);

        if (beforeShow) beforeShow();

        dialogPolyfill.registerDialog(modal);
        modal.showModal();

        if (afterShow) afterShow();

        const close = modal.querySelector('.close');
        if (close) {
            modal.querySelector('.close').addEventListener('click', e => {
                e.preventDefault();
                if (beforeClose) {
                    beforeClose();
                }
                modal.close();
            });
        }

        modal.addEventListener('click', e => {
            e.preventDefault();
            if (e.target === modal) {
                if (beforeClose) {
                    beforeClose();
                }
                modal.close();
            }
        });

        modal.addEventListener('close', () => {
            if (afterClose) {
                afterClose();
            }
        });
    }
};